import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/src/templates/Docs.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Slides = makeShortcode("Slides");
const Video = makeShortcode("Video");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Thoughts on culture`}</h2>
    <p>{`A good company culture will not fix your P&L or help you find/rediscover pmf. High margins on a product that people `}<strong parentName="p">{`love`}</strong>{` is the best way to build a sustainable organization. Successful businesses often succeed `}<em parentName="p">{`despite`}</em>{` their culture/org problems and founders' shortcomings.`}</p>
    <p>{`A good company culture can keep the ship stable as you chart through the choppy waters of finding your first 100 superusers, pivoting, and (in later stages) reacting to technology/platform shifts. "Company" is best defined as "a number of individuals gathered together, especially for a particular purpose". A good company culture can also help avoid the `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/The_Innovator%27s_Dilemma"
      }}>{`Innovator's Dilemma`}</a>{`.`}</p>
    <p>{`The culture of your company is yours to define. There are no rules. Ignoring the “status quo” can help sometimes. But it helps to not be an asshole about it, and be inclusive to ideas and opinions that don't fit into your framework (founders are stubborn :). Culture is reinforced through action, not words. Your words and culture decks are worthless. "Do as I say, not as I do" works for teachers and coaches, but not for CEOs.`}</p>
    <p>{`It's a tough balance to encourage high performance while maintaining psychological safety (a room with soft walls where you can push people around a bit, without getting hurt). To create space to bring up the "elephant in the room" problems without fear of judgement. To let the best ideas be heard. Ideas to improve the organization or ideas to improve the product. Ego/insecurity often gets in the way... yours and your team's. We are pressured to be "adults" and "in charge" but that can sometimes turn into hubris and bullying of others on our team. That happens, and is a big part of the human experience - even when it's uncomfortable. We're all human and wanting to help each other and be understood. A good CEO knows when to be an ant and when to be an elephant.`}</p>
    <h2>{`Books on leadership and culture`}</h2>
    <p><strong parentName="p">{`Good to Great`}</strong></p>
    <p><a parentName="p" {...{
        "href": "https://www.amazon.com/Good-Great-Some-Companies-Others/dp/0066620996"
      }}>{`Good to Great`}</a>{` by Jim Collins is the most data-driven book I've found on organization building. Collins and his team studied performance data of Fortune 500's to identify teams that consistently outperformed the market. They interviewed employees during the periods of growth to identify differentiating characteristics in culture and leadership style. Although the learnings are most relevant to later stage businesses, many of the core principles apply to startups as well.`}</p>
    <br />
    <p><strong parentName="p">{`Speed of Trust`}</strong></p>
    <p><a parentName="p" {...{
        "href": "https://www.amazon.com/SPEED-Trust-Thing-Changes-Everything/dp/074329730X"
      }}>{`Speed of Trust`}</a>{` by Stephen Covey was an essential read as I shifted my role from driving critical functions to trusting execs to take ownership. It also helped our team build systems to improve cross-functional communication, which is often an achilles heel for scaling orgs. Covey identifies characteristics of high and low trust organizations and describes strategies on how to extend increasing amounts of trust without doing so blindly.`}</p>
    <br />
    <p><strong parentName="p">{`High Output Management`}</strong></p>
    <p><a parentName="p" {...{
        "href": "https://www.amazon.com/High-Output-Management-Andrew-Grove/dp/0679762884"
      }}>{`High Output Management`}</a>{` by Andy Grove is perhaps the most recommended management book, and for good reason. Grove talks through the tactics on what worked to scale - and develop young leaders - during his tenure at Intel. I particularly found it helpful when thinking about modularizing our organization to put more agency in the hands of ICs.`}</p>
    <br />
    <p><strong parentName="p">{`Reinventing Organizations`}</strong></p>
    <p><a parentName="p" {...{
        "href": "https://www.amazon.com/Reinventing-Organizations-Creating-Inspired-Consciousness/dp/296013351X"
      }}>{`Reinventing Organizations`}</a>{` by Frederic Laloux presents 11 examples of organizations which operate with radically progressive organizational models. These examples demonstrate the extreme of giving agency and ownership to people on the front lines of the business. The research on new org models is recent and without large amounts of data to support the theories, but it really expanded my thinking on organization design. It helped me ask deeper questions and helped me return to first principles when designing our culture.`}</p>
    <br />
    <p><strong parentName="p">{`Crucial Conversations`}</strong></p>
    <p><a parentName="p" {...{
        "href": "https://www.amazon.com/Crucial-Conversations-Tools-Talking-Stakes/dp/1260474216"
      }}>{`Crucial Conversations`}</a>{` by Grenny, Patterson, McMillan, Switzler, Gregory presents framework on how to navigate critical (and often heated) discussions with colleagues. As Make School grew, my workload shifted from being task heavy to conversation heavy. Crucial conversation was helpful in improving my performance on the thing I spent most of my hours on - talking to my team.`}</p>
    <br />
    <p><strong parentName="p">{`No Hard Feelings`}</strong></p>
    <p><a parentName="p" {...{
        "href": "https://www.amazon.com/No-Hard-Feelings-Embracing-Emotions/dp/0525533834"
      }}>{`No Hard Feelings`}</a>{` by Liz Fosslien and Molly Duffy gives a perspective on emotions in the workplace, to help give perspectives on how your employees might be feeling as the organization scales.`}</p>
    <br />
    <p><strong parentName="p">{`The Death and Life of Great American Cities`}</strong></p>
    <p><a parentName="p" {...{
        "href": "https://www.amazon.com/Death-Life-Great-American-Cities/dp/067974195X"
      }}>{`The Death and Life of Great American Cities`}</a>{` by Jane Jacobs shares a perspective on emergent civic design. Jacobs' perspective contrasts Robert Moses' top down / heirarchical / "master planned" civic design. Top down design can often create silos (rigid departments challenged by cross-functional communication) rather than empowering individual agency and org-wide innovation.`}</p>
    <h2>{`Culture decks`}</h2>
    <p><strong parentName="p">{`Valve`}</strong></p>
    <Slides type="google" title="Valve" contentSrc="13XiWyb-XkFZIjGaSgxNomP4MXxNCHzcd" mdxType="Slides" />
    <br />
    <br />
    <br />
    <p><strong parentName="p">{`Make School`}</strong></p>
    <Slides type="google" title="Make School" contentSrc="1P0q4Kmi9mxjDj2eW9COwolwM2V8nOmgK" mdxType="Slides" />
    <br />
    <br />
    <br />
    <p><strong parentName="p">{`Netflix`}</strong></p>
    <Slides type="google" title="Netflix" contentSrc="1_HwrQhpdo8vYsz4IRHlUmbOVJR-H3kWu" mdxType="Slides" />
    <br />
    <br />
    <br />
    <p><strong parentName="p">{`Powerset`}</strong></p>
    <Slides type="google" title="Powerset" contentSrc="1aH6fBqHqk3iQZHfQxL4EjaNntYSzG2QA" mdxType="Slides" />
    <br />
    <br />
    <br />
    <p><strong parentName="p">{`Github`}</strong></p>
    <Video platform="vimeo" videoId="43684882" alt="Founder Coaching Discussion" mdxType="Video" />
    <br />
    <br />
    <br />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      